import { memo, Suspense } from "react";
import { Handle, Position, NodeProps, type Node } from "@xyflow/react";
import { CodeXml } from "lucide-react";

import styles from "./NodeTypes.module.css";
import useNodeClickHandler from "@/hooks/useNodeClick";
import { getDynamicIcon } from "@/custom/Generic/dynamic-icon";

type CustomWorkflowNodeType = Node & {
  data: {
    label: string;
    icon?: any;
  };
};

const WorkflowNode = ({
  id,
  data,
  selected,
}: NodeProps<CustomWorkflowNodeType>) => {
  // see the hook implementation for details of the click handler
  // calling onClick adds a child node to this node
  const onClick = useNodeClickHandler(id);

  const Icon = getDynamicIcon(data.icon || "code-xml");
  const fallback = <CodeXml className="size-[10px]" />;

  return (
    <div
      onClick={onClick}
      className={`${selected && "border-slate-900 bg-slate-100"} flex h-10 w-12 cursor-pointer flex-col items-center rounded-md border border-slate-400 bg-white p-2 text-[8px] font-semibold text-slate-500`}
    >
      <Suspense fallback={fallback}>
        <Icon className="size-[10px]" />{" "}
      </Suspense>
      <div className="absolute bottom-1 left-1/2 -translate-x-1/2">
        {" "}
        {data.label}
      </div>
      <Handle
        className={styles.handle}
        type="target"
        position={Position.Top}
        isConnectable={false}
      />
      <Handle
        className={styles.handle}
        type="source"
        position={Position.Bottom}
        isConnectable={false}
      />
    </div>
  );
};

export default memo(WorkflowNode);
