import { useEffect, useState } from "react";
import { Editor } from "@monaco-editor/react";
import { Panel } from "@xyflow/react";
import { Check, ChevronsUpDown } from "lucide-react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { blockTypes } from "@/custom/Generic/block-properties";

export const PropertyPanel = ({
  currentNode,
  setNodes,
}: {
  currentNode: any;
  setNodes: any;
}) => {
  const [script, setScript] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    type: "",
  });

  useEffect(() => {
    if (currentNode) {
      setFormData({
        name: currentNode.data.name || "",
        type: currentNode.data.type || "",
      });
      setScript(currentNode.data.script || "");
    }
  }, [currentNode]);

  useEffect(() => {
    setNodes((prevNodes: any) => {
      return prevNodes.map((node: any) => {
        if (node.id === currentNode.id) {
          return {
            ...node,
            data: {
              ...node.data,
              label:
                blockTypes.find(type => type.value === formData.type)?.label ||
                "Unknown",
              ...formData,
              icon:
                blockTypes.find(type => type.value === formData.type)?.icon ||
                "code-xml",
              script: formData.type === "script" ? script : node.data.script,
            },
          };
        }
        return node;
      });
    });
  }, [formData]);

  const handleEditorChange = (value: string | undefined) => {
    setScript(value || "");
  };

  return (
    <Panel position="top-right" className="relative h-[88vh]">
      <aside className="m-2 flex h-full w-72 flex-col justify-start rounded-lg border-[1px] border-slate-300 bg-white px-4 py-4">
        <div className="grid grid-cols-1 gap-x-9 gap-y-5">
          <div className="grid w-full items-start gap-1.5">
            <Label htmlFor="name" className="text-left">
              Name
            </Label>
            <Input
              type="text"
              id="name"
              placeholder="Enter block name"
              value={formData.name}
              onChange={e =>
                setFormData({
                  ...formData,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className="grid w-full items-start gap-1.5">
            <Label htmlFor="type" className="text-left">
              Type
            </Label>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  className="w-full justify-between font-normal text-slate-500"
                >
                  {formData.type
                    ? blockTypes.find(type => type.value === formData.type)
                        ?.label
                    : "Select type..."}
                  <ChevronsUpDown className="size-4 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-full p-0">
                <Command>
                  <CommandInput placeholder="Search type..." className="h-9" />
                  <CommandList>
                    <CommandEmpty>No type found.</CommandEmpty>
                    <CommandGroup>
                      {blockTypes.map(type => (
                        <CommandItem
                          key={type.value}
                          value={type.value}
                          onSelect={currentValue => {
                            setFormData({
                              ...formData,
                              type: currentValue,
                            });
                            setOpen(false);
                          }}
                        >
                          {type.label}
                          <Check
                            className={cn(
                              "ml-auto size-4",
                              formData.type === type.value
                                ? "opacity-100"
                                : "opacity-0",
                            )}
                          />
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>
          <Separator />
          {formData.type === "script" && (
            <div className="grid w-full items-start gap-1.5">
              <Label htmlFor="script" className="text-left">
                Script
              </Label>
              <Editor
                className="overflow-hidden rounded-md border-[1px] border-slate-300"
                width={"100%"}
                height="200px"
                defaultLanguage="javascript"
                value={script}
                onChange={handleEditorChange}
              />
            </div>
          )}
        </div>
      </aside>
    </Panel>
  );
};
