import { useState, useEffect } from "react";
import {
  ReactFlow,
  Background,
  Panel,
  Node,
  ReactFlowProvider,
  Edge,
  useNodesState,
  useEdgesState,
} from "@xyflow/react";
import { useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import useLayout from "@/hooks/useLayout";
import nodeTypes from "@/interface/nodeTypes";
import edgeTypes from "@/interface/edgeTypes";
import { WorkflowPanel, PropertyPanel } from "@/custom/Workflow/Panels";
import { del, get, post } from "@/lib/rest";
import { toast } from "../ui/use-toast";

import "@xyflow/react/dist/style.css";
import { uuid } from "@/lib/utils";
import { Play } from "lucide-react";

// ** Default configurations for nodes and edges **
const defaultNodes: Node[] = [
  {
    id: "1",
    data: { label: "+" },
    position: { x: 65, y: 0 },
    type: "placeholder",
  },
];
const defaultEdges: Edge[] = [];
const proOptions = { account: "paid-pro", hideAttribution: true };
const fitViewOptions = { padding: 0.95 };

function ReactFlowPro() {
  useLayout(); // Recalculates layout dynamically
  const { projectId } = useParams();

  // ** States **
  const [currentNode, setCurrentNode] = useState(null);
  const [workflows, setWorkflows] = useState<any>([]);
  const [selectedWorkflowDetails, setSelectedWorkflowDetails] =
    useState<any>(null);
  const [selectedWorkflowName, setSelectedWorkflowName] = useState("");
  const [rfInstance, setRfInstance] = useState<any>(null);
  const [nodes, setNodes] = useNodesState(defaultNodes);
  const [edges, setEdges] = useEdgesState(defaultEdges);

  // ** Fetch workflows on mount **
  useEffect(() => {
    fetchWorkflows();
  }, []);

  useEffect(() => {
    if (selectedWorkflowDetails) {
      setNodes(selectedWorkflowDetails?.configuration?.nodes || defaultNodes);
      setEdges(selectedWorkflowDetails?.configuration?.edges || defaultEdges);
    }
  }, [selectedWorkflowDetails]);

  const onNodeClick = (event: any, node: any) => {
    if (node.type === "placeholder") return;
    setCurrentNode(node);

    setWorkflows(
      workflows.map((workflow: any) =>
        workflow.id === selectedWorkflowDetails?.id
          ? { ...workflow, configuration: { nodes, edges } }
          : workflow,
      ),
    );
  };

  // ** API Calls **
  const fetchWorkflows = async () => {
    try {
      const response = await get({ url: `/api/project/${projectId}/workflow` });
      const fetchedWorkflows = response?.data?.workflows?.map(
        (workflow: { configuration: any }) => ({
          ...workflow,
          configuration: JSON.parse(JSON.stringify(workflow.configuration)),
        }),
      );
      setWorkflows(fetchedWorkflows);
      const [firstWorkflow] = fetchedWorkflows;
      setSelectedWorkflowDetails({
        ...firstWorkflow,
        configuration: JSON.parse(JSON.stringify(firstWorkflow.configuration)),
      });
      setSelectedWorkflowName(fetchedWorkflows[0]?.name);
      setNodes(fetchedWorkflows[0]?.configuration?.nodes || defaultNodes);
      setEdges(fetchedWorkflows[0]?.configuration?.edges || defaultEdges);
    } catch (error) {
      toast({
        variant: "destructive",

        title: "Error fetching workflows",
        description: "There was an error fetching workflows",
      });
    }
  };

  const createWorkflow = async () => {
    try {
      if (rfInstance) {
        await post({
          url: `/api/project/${projectId}/workflow`,
          data: {
            name: selectedWorkflowName,
            configuration: rfInstance.toObject(),
          },
        });
        fetchWorkflows();
        toast({
          title: "Workflow created successfully",
          description: "The workflow has been created successfully",
        });
      }
    } catch (error) {
      toast({
        variant: "destructive",

        title: "Error creating workflow",
        description: "There was an error creating the workflow",
      });
    }
  };
  const updateWorkflow = async () => {
    try {
      const workflow = workflows.find(
        (workflow: any) => workflow.id === selectedWorkflowDetails?.id,
      );
      if (rfInstance) {
        await post({
          url: `/api/project/${projectId}/workflow/${selectedWorkflowDetails?.id}`,
          data: workflow,
        });
        fetchWorkflows();
        toast({
          title: "Workflow updated successfully",
          description: "The workflow has been updated successfully",
        });
      }
    } catch (error) {
      toast({
        variant: "destructive",

        title: "Error updating workflow",
        description: "There was an error updating the workflow",
      });
    }
  };

  const fetchWorkflowDetails = async (workflowId: any) => {
    try {
      const response = await get({
        url: `/api/project/${projectId}/workflow/${workflowId}`,
      });
      const { workflow } = response?.data;

      const configuration =
        typeof workflow.configuration === "string"
          ? JSON.parse(workflow.configuration)
          : workflow.configuration;

      setSelectedWorkflowDetails({
        ...workflow,
        configuration,
      });

      // Update React Flow's nodes and edges state
      setNodes(configuration?.nodes || defaultNodes);
      setEdges(configuration?.edges || defaultEdges);

      setSelectedWorkflowName(workflow.name);
    } catch (error: any) {
      console.error(JSON.stringify(error));
      toast({
        variant: "destructive",
        title: "Error fetching workflow details",
        description: "There was an error fetching the workflow",
      });
    }
  };

  const deleteWorkflow = async (workflowId: any) => {
    try {
      await del({
        url: `/api/project/${projectId}/workflow/${workflowId}`,
        data: {},
      });
      fetchWorkflows();
      setSelectedWorkflowDetails(null);
      setSelectedWorkflowName("");
      toast({
        title: "Workflow deleted successfully",
        description: "The workflow has been deleted successfully",
      });
    } catch (error) {
      toast({
        variant: "destructive",

        title: "Error deleting workflow",
        description: "There was an error deleting the workflow",
      });
    }
  };

  // ** Utility Functions **

  // ** Handlers **
  const handleSave = async () => {
    if (selectedWorkflowDetails?.id && !selectedWorkflowDetails?.draft) {
      await updateWorkflow();
    } else {
      await createWorkflow();
    }
  };

  const handleDeleteConfiguaration = async () => {
    if (selectedWorkflowDetails?.id)
      await deleteWorkflow(selectedWorkflowDetails?.id);
  };

  const addNewWorkflow = () => {
    const baseName = "New Workflow";
    let newWorkflowName = baseName;
    let count = 1;

    // Check if a workflow with the same name already exists and increment the count
    while (
      workflows.some((workflow: any) => workflow.name === newWorkflowName)
    ) {
      newWorkflowName = `${baseName} ${count}`;
      count++;
    }

    const newWorkflow = {
      id: uuid(),
      name: newWorkflowName,
      configuration: { nodes: defaultNodes, edges: defaultEdges },
      draft: true,
    };

    setSelectedWorkflowDetails(newWorkflow);
    setWorkflows([...workflows, newWorkflow]);
    setSelectedWorkflowName(newWorkflowName);
  };

  const handleDelete = (workflow: any) => {
    if (workflow.draft) {
      setWorkflows(workflows.filter((w: any) => w.id !== workflow.id));
      setSelectedWorkflowDetails(null);
      setSelectedWorkflowName("");
    } else handleDeleteConfiguaration();
  };

  // ** UI Layout **
  return (
    <div className="relative flex h-full w-screen flex-row">
      {/* Workflow Selection Panel */}
      <WorkflowPanel
        workflows={workflows}
        newWorkflow={addNewWorkflow}
        selectedWorkflowDetails={selectedWorkflowDetails}
        selectWorkflow={workflow => {
          if (!workflow.draft) {
            fetchWorkflowDetails(workflow?.id);
          } else {
            setSelectedWorkflowDetails(workflow);
            setSelectedWorkflowName(workflow.name);

            // Update React Flow's nodes and edges state for drafts
            setNodes(workflow?.configuration?.nodes || defaultNodes);
            setEdges(workflow?.configuration?.edges || defaultEdges);
          }
        }}
        handleDelete={handleDelete}
      />

      {/* Property Panel for Node Details */}
      {currentNode && (
        <PropertyPanel currentNode={currentNode} setNodes={setNodes} />
      )}

      {/* React Flow Graph */}
      {selectedWorkflowDetails && (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          defaultNodes={defaultNodes}
          defaultEdges={defaultEdges}
          elementsSelectable={true}
          proOptions={proOptions}
          fitView
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          fitViewOptions={fitViewOptions}
          onNodeClick={onNodeClick}
          minZoom={0.2}
          nodesDraggable={true}
          nodesConnectable={false}
          zoomOnDoubleClick={false}
          onInit={setRfInstance}
          deleteKeyCode={null}
          onPaneClick={() => setCurrentNode(null)}
        >
          {/* Workflow Name Input */}
          <Panel position="top-center">
            <input
              className="rounded-md border-2 px-4 py-2"
              type="text"
              placeholder="Workflow name"
              value={selectedWorkflowName}
              onChange={e => {
                setSelectedWorkflowName(e.target.value);
                setWorkflows(
                  workflows.map((workflow: any) =>
                    workflow.id === selectedWorkflowDetails?.id
                      ? { ...workflow, name: e.target.value }
                      : workflow,
                  ),
                );
                setSelectedWorkflowDetails({
                  ...selectedWorkflowDetails,
                  name: e.target.value,
                });
              }}
            />
          </Panel>

          {/* Save & Delete Buttons */}
          <div
            className={`absolute ${currentNode ? "right-[calc(240px+80px)]" : "right-2"} top-4 z-30 flex items-center space-x-2`}
          >
            <Button className="h-10 space-x-2">
              <Play className="size-4" strokeWidth={3} />
              <div>Run</div>
            </Button>
            <Button className="mr-2 h-10 bg-emerald-500" onClick={handleSave}>
              Save
            </Button>
          </div>

          <Background />
        </ReactFlow>
      )}
    </div>
  );
}

function ReactFlowWrapper() {
  return (
    <div className="h-[93vh] w-screen overflow-hidden">
      <ReactFlowProvider>
        <ReactFlowPro />
      </ReactFlowProvider>
    </div>
  );
}

export default ReactFlowWrapper;
