import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import Loading from "@/custom/Loading/loading";
import { Switch } from "../ui/switch";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

const CreateProjectModal = ({
  setNewProjectModal,
  addProject,
  loading,
}: {
  setNewProjectModal: (value: boolean) => void;
  addProject: any;
  loading: boolean;
}) => {
  const [projectName, setProjectName] = useState("");
  const [region, setRegion] = useState("aws-ap-southeast-1");
  const [cloned, setCloned] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [databaseUrl, setDatabaseUrl] = useState("");

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (cloned) {
      addProject({
        cloned,
        projectName,
        projectId,
        connectionUrl: databaseUrl,
      });
    } else {
      addProject({ cloned, projectName, region });
    }
  };

  return (
    <div className="absolute left-0 top-0 z-20 flex h-screen w-screen items-center justify-center overflow-hidden bg-slate-950 bg-opacity-70 backdrop-blur-sm">
      <form
        onSubmit={onSubmit}
        className="grid min-h-[230px] w-[40vw] min-w-[332px] gap-4 justify-self-center"
      >
        <Card className="flex w-full justify-start">
          {!loading ? (
            <CardHeader className="w-full">
              <CardTitle className="w-fit">Create New Project</CardTitle>
              <CardContent className="px-0">
                <div className="col-span-1 flex flex-col space-y-2">
                  <Label className="w-fit">Cloned</Label>
                  <Switch
                    checked={cloned}
                    onCheckedChange={(checked: boolean) => setCloned(checked)}
                  />
                </div>
                <div className="grid w-full gap-2 py-4">
                  <Label htmlFor="project_name" className="text-left">
                    Name
                  </Label>
                  <Input
                    name="project_name"
                    type="project_name"
                    placeholder="Project Name"
                    value={projectName}
                    onChange={e => {
                      setProjectName(e.target.value);
                    }}
                    required
                  />
                </div>
                {!cloned && (
                  <div className="grid w-full gap-2 py-4">
                    <Label htmlFor="region_name" className="text-left">
                      Region
                    </Label>
                    <Select
                      defaultValue={region}
                      value={region}
                      onValueChange={(value: string) => setRegion(value)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select a Type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="aws-ap-southeast-1">
                            <div className="flex items-center space-x-2">
                              <p>Singapore</p>
                            </div>
                          </SelectItem>
                          <SelectItem value="aws-eu-central-1">
                            <div className="flex items-center space-x-2">
                              <p>Frankfurt</p>
                            </div>
                          </SelectItem>
                          <SelectItem value="aws-ap-southeast-2">
                            <div className="flex items-center space-x-2">
                              <p>Sydney</p>
                            </div>
                          </SelectItem>
                          <SelectItem value="aws-us-east-1">
                            <div className="flex items-center space-x-2">
                              <p>N. Virginia</p>
                            </div>
                          </SelectItem>
                          <SelectItem value="aws-us-east-2">
                            <div className="flex items-center space-x-2">
                              <p>Ohio</p>
                            </div>
                          </SelectItem>
                          <SelectItem value="aws-us-west-2">
                            <div className="flex items-center space-x-2">
                              <p> Oregon</p>
                            </div>
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                )}
                {cloned && (
                  <>
                    <div className="grid w-full gap-2 py-4">
                      <Label htmlFor="project_id" className="text-left">
                        Project ID
                      </Label>
                      <Input
                        name="project_id"
                        type="project_id"
                        placeholder="Project ID"
                        value={projectId}
                        onChange={e => {
                          setProjectId(e.target.value);
                        }}
                      />
                    </div>
                    <div className="grid w-full gap-2 py-4">
                      <Label htmlFor="database_url" className="text-left">
                        Database URL
                      </Label>
                      <Input
                        name="database_url"
                        type="database_url"
                        placeholder="Database URL"
                        value={databaseUrl}
                        onChange={e => {
                          setDatabaseUrl(e.target.value);
                        }}
                      />
                    </div>
                  </>
                )}
              </CardContent>
              <div className="flex w-full justify-end">
                <div className="space-x-2">
                  <Button
                    variant="outline"
                    type="button"
                    onClick={() => {
                      setNewProjectModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant={"default"} type="submit">
                    Create
                  </Button>
                </div>
              </div>
            </CardHeader>
          ) : (
            <Loading />
          )}
        </Card>
      </form>
    </div>
  );
};

export default CreateProjectModal;
