import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { toast } from "@/components/ui/use-toast";
import { post } from "@/lib/rest";
import axios from "axios";
import { useState, useEffect } from "react";

const Image = () => {
  // Upload States
  const [isMultipleUpload, setIsMultipleUpload] = useState(true);
  const [isPrivateUpload, setIsPrivateUpload] = useState(false);
  const [pictures, setPictures] = useState<FileList | null>(null);
  const [uploadFileLinks, setUploadFileLinks] = useState<string[]>([]);

  // Download States
  const [isMultipleDownload, setIsMultipleDownload] = useState(true);
  const [isPrivateDownload, setIsPrivateDownload] = useState(false);
  const [downloadLink, setDownloadLink] = useState<string>();

  useEffect(() => {
    if (pictures) {
      const links = Array.from(pictures).map(file => URL.createObjectURL(file));
      setUploadFileLinks(links);
    }
  }, [pictures]);

  async function handleUploadChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      setPictures(e.target.files);
    }
  }

  async function handleDownloadChange(e: React.ChangeEvent<HTMLInputElement>) {
    // Implement logic to handle download changes
    setDownloadLink(e.target.value);
  }

  async function pushProfilePhotoToS3(presignedUrl: string, uploadPhoto: File) {
    try {
      const config = {
        method: "put",
        maxBodyLength: Infinity,
        url: presignedUrl,
        headers: {
          "Content-Type": uploadPhoto.type,
        },
        data: uploadPhoto,
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      toast({
        variant: "default",
        title: "Image uploaded successfully",
      });
    } catch (error) {
      console.log(error);
      toast({
        variant: "destructive",
        title: "Error uploading image",
        description: error as any,
      });
    }
  }

  const handleUploadSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (pictures) {
      for (const file of Array.from(pictures)) {
        const data = await getS3SignUrl(file.name, file.type);
        if (data?.data?.url) {
          const presignedUrl = data.data.url;
          await pushProfilePhotoToS3(presignedUrl, file);
        }
      }
    }
  };

  const handleDownloadSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = {
      columnName: "images",
      imageurl: downloadLink,
      tableName: "Employee2",
      isPrivate: isPrivateDownload,
      projectId: "22",
      tableId: 1,
      multiple: isMultipleDownload,
    };
    if (downloadLink && isPrivateDownload) {
      try {
        const response = await post({ url: "/api/imgdownloadurl", data });
        console.log(response.data);
        if (response.data?.url) {
          const downloadUrl = response.data.url;
          window.open(downloadUrl, "_blank");
        } else {
          // Handle the case when the download link is not available
        }
        // Handle the response data
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    } else {
      window.open(downloadLink, "_blank");
    }
  };

  async function getS3SignUrl(fileName: string, fileType: string) {
    const multiple = isMultipleUpload;
    const tableName = "Employee";
    const columnName = "image";
    const projectId = "23";
    const tableId = 1;
    const data = {
      imageName: fileName,
      imageType: fileType,
      tableName: tableName,
      columnName: columnName,
      projectId: projectId,
      isPrivate: isPrivateUpload,
      multiple: multiple,
      tableId: tableId,
    };
    let presignedUrl: any;
    await post({ url: "/api/imgupload", data })
      .then(response => {
        toast({
          variant: "default",
          title: "Image link created successfully",
        });
        presignedUrl = response;
      })
      .catch(error => {
        toast({
          variant: "destructive",
          title: "Error creating link",
          description: error.message,
        });
        console.error(error);
      });

    return presignedUrl;
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center space-x-4">
      <Card>
        <CardHeader>
          <CardTitle>Upload Image</CardTitle>
        </CardHeader>
        <CardContent>
          <form
            onSubmit={handleUploadSubmit}
            className="grid items-center gap-1.5 gap-y-2"
          >
            <Label htmlFor="uploadType">Upload Type</Label>
            <div className="flex items-center space-x-2">
              {uploadFileLinks.map((link, index) => (
                <img
                  key={index}
                  className="h-20 w-20 object-cover"
                  src={link}
                  alt=""
                />
              ))}
            </div>
            <div className="flex items-center justify-between space-x-2">
              <div className="flex items-center space-x-2">
                <Switch
                  id="uploadType"
                  checked={isMultipleUpload}
                  onClick={() => setIsMultipleUpload(!isMultipleUpload)}
                />
                <Label htmlFor="uploadType">
                  {isMultipleUpload ? "Multiple Images" : "Single Image"}
                </Label>
              </div>
              <div className="flex items-center space-x-2">
                <Switch
                  id="privateUpload"
                  checked={isPrivateUpload}
                  onClick={() => setIsPrivateUpload(!isPrivateUpload)}
                />
                <Label htmlFor="privateUpload">
                  {isPrivateUpload ? "Private" : "Public"}
                </Label>
              </div>
            </div>
            <Label htmlFor="picture">Picture</Label>
            <Input
              id="picture"
              type="file"
              multiple={isMultipleUpload}
              onChange={handleUploadChange}
            />
            <button
              type="submit"
              className="mt-4 rounded bg-blue-500 px-4 py-2 text-white"
            >
              Submit
            </button>
          </form>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Download Image</CardTitle>
        </CardHeader>
        <CardContent>
          <form
            onSubmit={handleDownloadSubmit}
            className="grid items-center gap-1.5 gap-y-2"
          >
            <Label htmlFor="downloadType">Download Type</Label>
            <div className="flex items-center space-x-2">
              {
                <img
                  key={downloadLink}
                  className="h-20 w-20 object-cover"
                  src={downloadLink}
                  alt=""
                />
              }
            </div>
            <div className="flex items-center justify-between space-x-2">
              <div className="flex items-center space-x-2">
                <Switch
                  id="downloadType"
                  checked={isMultipleDownload}
                  onClick={() => setIsMultipleDownload(!isMultipleDownload)}
                />
                <Label htmlFor="downloadType">
                  {isMultipleDownload ? "Multiple Images" : "Single Image"}
                </Label>
              </div>
              <div className="flex items-center space-x-2">
                <Switch
                  id="privateDownload"
                  checked={isPrivateDownload}
                  onClick={() => setIsPrivateDownload(!isPrivateDownload)}
                />
                <Label htmlFor="privateDownload">
                  {isPrivateDownload ? "Private" : "Public"}
                </Label>
              </div>
            </div>
            <Label htmlFor="downloadLink">Download Link</Label>
            <Input
              id="downloadLink"
              type="text"
              value={downloadLink || ""}
              onChange={handleDownloadChange}
            />
            <button
              type="submit"
              className="mt-4 rounded bg-blue-500 px-4 py-2 text-white"
            >
              download
            </button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default Image;
