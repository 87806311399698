import { NodeTypes } from "@xyflow/react";

import PlaceholderNode from "./PlaceholderNode";
import WorkflowNode from "./WorkflowNode";

// two different node types are needed for our example: workflow and placeholder nodes
const nodeTypes: NodeTypes = {
  placeholder: PlaceholderNode,
  trigger: WorkflowNode,
  script: WorkflowNode,
  query: WorkflowNode,
};

export default nodeTypes;
