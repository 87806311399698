import { EdgeProps, getBezierPath } from "@xyflow/react";

import useEdgeClick from "@/hooks/useEdgeClick";
import styles from "./EdgeTypes.module.css";
import { Plus } from "lucide-react";

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
}: EdgeProps) {
  // see the hook for implementation details
  // onClick adds a node in between the nodes that are connected by this edge
  const onClick = useEdgeClick(id);

  const [edgePath, edgeCenterX, edgeCenterY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className={"pointer-events-auto fill-none stroke-slate-300"}
        d={edgePath}
        markerEnd={markerEnd}
      />
      <g
        transform={`translate(${edgeCenterX}, ${edgeCenterY})`}
        onClick={onClick}
      >
        <circle
          cx={0} // Center x-coordinate
          cy={0} // Center y-coordinate
          r={8} // Radius
          strokeWidth={1}
          className="cursor-pointer fill-white stroke-slate-300"
        />
        <foreignObject x="-6" y="-6" width="12" height="12">
          <div className="flex h-full w-full items-center justify-center stroke-slate-300 text-slate-300">
            <Plus className="h-3 w-3" strokeWidth={2} />
          </div>
        </foreignObject>
      </g>
    </>
  );
}
