import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useDatabaseConfig } from "@/hooks/useDatabaseConfig";

const DeleteField = ({ table, column, closeAction }: any) => {
  const { config, cleanRelations } = useDatabaseConfig();

  const confirmAction = () => {
    const newConfig = {
      ...config,
      tables: config.tables.map(tab => {
        return {
          ...tab,
          columns: tab.columns.filter(col => col.id !== column.id),
        };
      }),
    };

    cleanRelations(newConfig);
    closeAction();
  };

  return (
    <div className="absolute left-0 top-0 z-20 flex h-screen w-screen items-center justify-center bg-slate-950 bg-opacity-70 backdrop-blur-sm">
      <Card className="flex justify-start">
        <CardHeader>
          <CardTitle className="w-fit">
            You are about to delete {column?.name}
          </CardTitle>
          <CardContent className="w-fit pl-0">
            <div className="w-fit">
              This will permanently delete {column?.name} from {table?.name}.
              Are you sure?
            </div>
          </CardContent>
          <div className="flex w-full justify-end">
            <div className="space-x-2">
              <Button variant="outline" onClick={() => closeAction()}>
                Cancel
              </Button>
              <Button variant={"destructive"} onClick={confirmAction}>
                Delete
              </Button>
            </div>
          </div>
        </CardHeader>
      </Card>
    </div>
  );
};

export default DeleteField;
