import { memo } from "react";
import { Handle, Position, NodeProps, BuiltInNode } from "@xyflow/react";
import cx from "classnames";

import styles from "./NodeTypes.module.css";
import usePlaceholderClick from "@/hooks/usePlaceholderClick";
import { Plus } from "lucide-react";

const PlaceholderNode = ({ id, data }: NodeProps<BuiltInNode>) => {
  // see the hook implementation for details of the click handler
  // calling onClick turns this node and the connecting edge into a workflow node
  const onClick = usePlaceholderClick(id);

  return (
    <div
      onClick={onClick}
      className={`flex h-[18px] w-[18px] items-center justify-center rounded-full bg-slate-900`}
      title="click to add a node"
    >
      <div>
        <Plus className="size-[12px] text-white" />
      </div>
      <Handle
        className={styles.handle}
        type="target"
        position={Position.Top}
        isConnectable={false}
      />
      <Handle
        className={styles.handle}
        type="source"
        position={Position.Bottom}
        isConnectable={false}
      />
    </div>
  );
};

export default memo(PlaceholderNode);
