import { DatabaseConfig } from "@/interface/config";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useConfigStore = create(
  devtools(set => ({
    config: {
      version: "v1",
      database: "sqlite3",
      tables: [],
    },
    oldConfig: {
      version: "v1",
      database: "sqlite3",
      tables: [],
    },
    project: {},
    setConfig: (newConfig: DatabaseConfig) =>
      set(() => ({ config: newConfig })),
    setOldConfig: (oldConfig: DatabaseConfig) =>
      set(() => ({ oldConfig: oldConfig })),
    setProject: (project: any) => set(() => ({ project: project })),
  })),
);
