import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Column, Table } from "@/interface/config";
import { ColumnUpdate } from "./ColumnUpdate";
import { useState } from "react";
import { useDatabaseConfig } from "@/hooks/useDatabaseConfig";

const CreateFieldModal = ({
  table: selectedTable,
  column: selectedColumn,
  closeAction,
  isEditMode = false,
}: {
  table: Table;
  column: Column;
  closeAction: () => void;
  isEditMode?: boolean;
}) => {
  const [column, setColumn] = useState(selectedColumn);
  const { config, setConfig, cleanRelations } = useDatabaseConfig();

  function saveTable() {
    let newConfig;
    if (isEditMode) {
      const updatedTable = {
        ...selectedTable,
        columns: [
          ...selectedTable.columns.map(col => {
            if (col.id === selectedColumn.id) return column;
            return col;
          }),
        ],
      };
      newConfig = {
        ...config,
        tables: [
          ...config.tables.map(tab => {
            if (tab.id === selectedTable.id) return updatedTable;
            return tab;
          }),
        ],
      };
    } else {
      const updatedTable = {
        ...selectedTable,
        columns: [...selectedTable.columns, column],
      };
      newConfig = {
        ...config,
        tables: [
          ...config.tables.map(tab => {
            if (tab.id === selectedTable.id) return updatedTable;
            return tab;
          }),
        ],
      };
    }
    cleanRelations(newConfig);
    closeAction();
  }

  return (
    <div className="absolute left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-slate-950 bg-opacity-70 backdrop-blur-sm">
      <Card className="h-fit max-h-[80%] w-fit min-w-[40rem] overflow-y-auto">
        <CardHeader className="flex w-full justify-start">
          <CardTitle className="w-fit">
            {isEditMode ? "Edit" : "Create"} Field
          </CardTitle>
        </CardHeader>
        <CardContent>
          <ColumnUpdate
            selectedTable={
              isEditMode
                ? selectedTable
                : {
                    ...selectedTable,
                    columns: [...selectedTable.columns, column],
                  }
            }
            selectedColumn={column}
            setSelectedColumn={setColumn}
          />
        </CardContent>
        <CardFooter className="flex justify-end space-x-2 pt-4">
          <Button className="w-fit" variant="outline" onClick={closeAction}>
            Cancel
          </Button>
          <Button className="w-fit" onClick={saveTable}>
            {isEditMode ? "Save" : "Create"}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default CreateFieldModal;
