import React, { useEffect, useState } from "react";
import Logo from "../../assets/logo.svg";
import { Settings } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

const NavComponent = ({
  setSettingModal,
}: {
  setSettingModal: (a: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  useEffect(() => {
    navigate(
      `/project/${projectId}/${localStorage.getItem("section") || "data"}`,
    );
  }, []);

  const sectionSetter = (section: string) => {
    localStorage.setItem("section", section);
  };
  return (
    <nav className="flex h-12 items-center justify-between bg-slate-900">
      <div className="flex h-full items-center justify-start px-5">
        <img
          src={Logo}
          alt="logo"
          style={{ height: 15, width: "auto" }}
          className="h-full w-full cursor-pointer object-cover dark:brightness-[0.2] dark:grayscale"
          onClick={() => {
            navigate("/home");
          }}
        />
      </div>
      <Tabs
        defaultValue={localStorage.getItem("section") || "data"}
        className="w-[400px] bg-transparent"
      >
        <TabsList className="space-x-2 bg-transparent">
          <TabsTrigger
            value="data"
            onClick={() => {
              sectionSetter("data");
              navigate(`/project/${projectId}/data`);
            }}
          >
            Data
          </TabsTrigger>
          <TabsTrigger
            value="ui"
            onClick={() => {
              sectionSetter("ui");
              navigate(`/project/${projectId}/ui`);
            }}
          >
            UI
          </TabsTrigger>
          <TabsTrigger
            value="workflow"
            onClick={() => {
              sectionSetter("workflow");
              navigate(`/project/${projectId}/workflow`);
            }}
          >
            Workflow
          </TabsTrigger>
        </TabsList>
      </Tabs>
      <div className="px-4 text-white">
        <Settings onClick={() => setSettingModal(true)} />
      </div>
    </nav>
  );
};

export default NavComponent;
