import { Panel } from "@xyflow/react";
import { Button } from "@/components/ui/button";
import { Check, Ellipsis, Plus, Trash2 } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

export const WorkflowPanel = ({
  workflows,
  newWorkflow,
  selectedWorkflowDetails,
  selectWorkflow,
  handleDelete,
}: {
  workflows: any[];
  newWorkflow: () => void;
  selectedWorkflowDetails: any;
  selectWorkflow: (workflow: any) => void;
  handleDelete: (id: any) => void;
}) => {
  return (
    <Panel position="top-left" className="h-[88vh]">
      <aside className="m-2 flex h-full w-60 flex-col justify-between rounded-lg border border-slate-300 bg-slate-100 px-4 py-4">
        {/* Header */}
        <div className="flex justify-between font-medium text-slate-600">
          <span>Workflow</span>
          <Plus
            className="cursor-pointer text-slate-400"
            strokeWidth={1}
            onClick={newWorkflow}
          />
        </div>
        <Separator className="my-1 mb-2" />

        {/* Workflow List */}
        <div className="h-full w-full space-y-2 rounded-md">
          {workflows.length > 0 ? (
            workflows.map(workflow => {
              const isSelected = selectedWorkflowDetails?.id === workflow?.id;
              return (
                <div
                  key={workflow.id}
                  onClick={() => selectWorkflow(workflow)}
                  className={`flex cursor-pointer items-center justify-between rounded-md p-1 ${
                    isSelected
                      ? "font-semibold text-slate-900"
                      : "text-slate-600"
                  }`}
                >
                  {/* Workflow Name */}
                  <div className="flex items-center">
                    {isSelected && <Check className="size-4" />}
                    <span className={`${isSelected ? "pl-2" : "pl-6"}`}>
                      {workflow.name}
                    </span>
                  </div>

                  {/* Workflow Actions */}
                  <div className="flex items-center">
                    {workflow.draft && (
                      <div className="mr-2 h-2 w-2 rounded-full bg-orange-500"></div>
                    )}
                    <Popover>
                      <PopoverTrigger asChild>
                        <div className="size-fit cursor-pointer p-1">
                          <Ellipsis className="h-4 w-4" />
                        </div>
                      </PopoverTrigger>
                      <PopoverContent className="w-[200px] p-2">
                        <Button
                          variant="ghost"
                          className="flex w-full items-center justify-start text-red-600"
                          onClick={e => {
                            e.stopPropagation(); // Prevent triggering workflow selection
                            handleDelete(workflow);
                          }}
                        >
                          <Trash2 className="mr-2 h-4 w-4" />
                          Delete
                        </Button>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex h-full items-center justify-center text-slate-400">
              No workflow available
            </div>
          )}
        </div>
      </aside>
    </Panel>
  );
};
